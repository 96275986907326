/** @jsx jsx */
import { jsx, Box, Container, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { Layout } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  HeroGradient,
  CentersOfExcellenceSideNav,
  PatientStoryGridCenters,
  CallToActionSchedule,
} from "../components/elements"

const CenterOfExcellenceConditions = ({ data, pageContext }) => {
  const page = data.datoCmsCenterOfExcellence

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const slug = pageContext.slug

  return (
    <Layout data-datocms-noindex>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Hero>
        <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
          <HeroGradient>
            <Container sx={{ px: "1em", py: [5, 5, 6] }}>
              <Styled.h1>{page.title}</Styled.h1>
              <Breadcrumb crumbs={crumbs} crumbSeparator=" / " />
            </Container>
          </HeroGradient>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </BackgroundImage>
      </Hero>
      <Container sx={{ px: "1em", py: 5 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ paddingRight: [0, 0, 3], width: ["full", "full", "70%"] }}>
            <Styled.h6 as="p">{page.introContent}</Styled.h6>
          </Box>
          <Box sx={{ width: ["full", "full", "30%"] }}>
            <CallToActionSchedule />
          </Box>

          {/* Sidebar */}
          <Box
            as="aside"
            id="sidebar"
            sx={{
              bg: ["transparent", "transparent", "warmgray"],
              flexBasis: "sidebar",
            }}
            data-datocms-noindex
            width={["full"]}
          >
            <CentersOfExcellenceSideNav page={page} />
          </Box>

          <Box
            as="main"
            sx={{
              flexGrow: 99999,
              flexBasis: 0,
              minWidth: 320,
            }}
          >
            {/* Content */}
            <Box as="section" id="content">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={{ width: ["full"], pl: [0, 0, 4] }}>
                  <Styled.h2 sx={{ mt: 0 }}>Conditions We Treat</Styled.h2>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html:
                        page.conditionsWeTreatNode.childMarkdownRemark.html,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <PatientStoryGridCenters slug={slug} />
    </Layout>
  )
}

export default CenterOfExcellenceConditions

export const query = graphql`
  query centerOfExcellenceConditionsPageQuery($slug: String!) {
    datoCmsCenterOfExcellence(slug: { eq: $slug }) {
      conditionsWeTreat
      conditionsWeTreatNode {
        childMarkdownRemark {
          html
        }
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      resources
      resourcesNode {
        childMarkdownRemark {
          html
        }
      }
      relatedServicesIntroNode {
        childMarkdownRemark {
          html
        }
      }
      relatedProgramsIntroNode {
        childMarkdownRemark {
          html
        }
      }
      introContent
      id
      position
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      meta {
        status
      }
      images {
        url
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      relatedPrograms {
        title
        slug
        id
      }
      relatedServices {
        id
        slug
        title
      }
      relatedBlogs {
          author {
            slug
            name
          }
          title
          slug
          date(formatString: "MM/d/yyyy")
          id
          meta {
            status
          }
          blogImage {
            fluid(
                  maxWidth: 240
                  imgixParams: { fm: "jpg", auto: "compress" }
              ) {...GatsbyDatoCmsFluid}
          }
      }
      video {
        url
      }
      logos {
        url
        logo {
          alt
          fluid(maxWidth: 250, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        id
      }
      seoMetaTags {
        tags
      }
    }
  }
`
